import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { Button, Modal, ModalBody } from "reactstrap";

const TransactionSuccessModal = (props, context) => {
  const { success, resourceType, action } = props;

  const [modal, setModal] = useState(false);

  const getThisResourceTypeString = () => {
    const translations = {
      mint: context.t("a token"),
    };
    return translations[resourceType]
      ? translations[resourceType]
      : resourceType;
  };

  const getActionString = () => {
    const translations = {
      minted: context.t("minted"),
    };
    return translations[action] ? translations[action] : resourceType;
  };

  useEffect(() => {
    if (success === true) setModal(true);
  }, []);

  const toggleModal = () => {
    setModal(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggleModal}
      centered
      className="border-0 shadow-sm"
    >
      <div className="text-center pt-3">
        <IoMdCheckmarkCircleOutline size={90} color="green" />
      </div>
      <ModalBody className="px-5">
        <div className="mx-auto text-center">
          <h1 className="h2">{context.t("Success!")}</h1>
          <p className="text-muted">
            {context.t("You successfully {action} {resourceType}", {
              action: getActionString(),
              resourceType: getThisResourceTypeString(),
            })}
          </p>
          <p>
            <strong>You can now unlock contents.</strong> If your token does not
            show up immediately we are importing it still from the blockchain.
            Please come back in a minute and/or refresh.
          </p>
        </div>
        <div className="text-center">
          <Button
            onClick={toggleModal}
            outline
            className="my-3 w-100 text-uppercase"
            color="success"
          >
            {context.t("ok")}
          </Button>
          <small className="text-muted text-center">
            {context.t("click to get back to overview screen")}
          </small>
        </div>
      </ModalBody>
    </Modal>
  );
};

/**  define proptype for the 'translation' function  */
TransactionSuccessModal.contextTypes = {
  t: PropTypes.func,
};

TransactionSuccessModal.propTypes = {
  success: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

export default TransactionSuccessModal;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { Col, Row } from "reactstrap";
import { getMintTierIdsByContract } from "../../../redux/mintTiers";
import { genericAbiRead } from "../../../redux/contracts";
import { getContractByAddress } from "../../../redux/contracts";
import { getContractAttributes } from "../../../redux/contracts";
import MintPassCard from "../MintPassCard";
// import TrunkatedAddress from "../../Utils/TrunkatedAddress";
import NetworkBar from "../../NetworkBar/NetworkBar-component";
import MintSoldoutScreen from "../MintSoldoutScreen/MintSoldoutScreen-component";

const ContractMints = (props, context) => {
  const { contractAddress } = props;

  const { account } = useWeb3React();
  const dispatch = useDispatch();

  const [totalSupply, setTotalSupply] = useState(0);
  const [tokenLimit, setTokenLimit] = useState(0);

  const mintTierIds = useSelector((state) =>
    getMintTierIdsByContract(state, contractAddress)
  );

  const contractByAddress = useSelector((state) =>
    getContractByAddress(state, contractAddress)
  );

  const contractData = useSelector((state) =>
    getContractAttributes(state, contractByAddress)
  );

  const getTotalSupply = async () => {
    if (!contractData?.total_supply_abi?.name) return true;
    dispatch(
      genericAbiRead({
        functionAbi: contractData?.total_supply_abi,
        contractAddress: contractData?.address,
        account: account,
        genericInputs: {
          key: account,
        },
        successCallbackFnc: (data) => {
          console.log("total supply", data);
          setTotalSupply(data?._isBigNumber ? data?.toString() : data);
          // dispatch(mintingInProgressSuccess());
        },
        errorCallbackFnc: (data) => {
          // alert("Error on Available Mint Amount");
          // alert(data);
          console.log("total supply error", data);
          // dispatch(mintError(data));
        },
      })
    );
  };

  const getTokenLimit = async () => {
    if (!contractData?.token_limit_abi?.name) return true;
    dispatch(
      genericAbiRead({
        functionAbi: contractData?.token_limit_abi,
        contractAddress: contractData?.address,
        account: account,
        genericInputs: {
          key: account,
        },
        successCallbackFnc: (data) => {
          console.log("token limit", data);
          setTokenLimit(data?._isBigNumber ? data?.toString() : data);
          // dispatch(mintingInProgressSuccess());
        },
        errorCallbackFnc: (data) => {
          // alert("Error on Available Mint Amount");
          // alert(data);
          console.log("token limit error", data);
          // dispatch(mintError(data));
        },
      })
    );
  };

  useEffect(() => {
    getTokenLimit();
    const interval = setInterval(() => {
      getTotalSupply();
    }, 5000); //refresh every 5 seconds enabled and available amount
    return () => clearInterval(interval);
  }, []);

  if (mintTierIds.length < 1) return <></>;
  return (
    <>
      <NetworkBar
        contractAddress={contractAddress}
        contractByAddress={contractByAddress}
      />
      <div className="text-center">
        <h2 className="my-3 fw-bold">
          {context.t("Available Mint Tiers for your Wallet")}
        </h2>
        <Row>
          {account &&
            !!totalSupply &&
            !!tokenLimit &&
            totalSupply == tokenLimit && (
              <MintSoldoutScreen contractAddress={contractAddress} />
            )}

          {!!totalSupply && !!tokenLimit && (
            <div className="my-5 fw-bold">
              <p className="h2 fw-bold">
                {totalSupply} / {tokenLimit}
              </p>
              <small>{context.t("Already minted in total")}</small>
            </div>
          )}

          {mintTierIds?.map((mintTierId) => (
            <Col xs={12} md={6} key={mintTierId}>
              <MintPassCard mintTierId={mintTierId} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

/**  define proptype for the 'translation' function  */
ContractMints.contextTypes = {
  t: PropTypes.func,
};

ContractMints.propTypes = {
  contractAddress: PropTypes.string,
};

ContractMints.defaultProps = {};

export default ContractMints;

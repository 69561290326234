export default function CheckCurrency() {
  let compResponse = null;

  if (window.ethereum.networkVersion === (0x01).toString(16)) {
    compResponse = "ETH";
  }
  if (window.ethereum.networkVersion === (0x4).toString(16)) {
    compResponse = "RinkebyETH";
  }
  if (window.ethereum.networkVersion === (0x137).toString(16)) {
    compResponse = "MATIC";
  }

  if (window.ethereum.networkVersion === (0x13881).toString(16)) {
    compResponse = "MATIC";
  }
  return compResponse;
}

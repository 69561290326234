import { Card, CardBody } from "reactstrap";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { getMintTierAttributes } from "../../../redux/mintTiers";
import { genericAbiRead } from "../../../redux/contracts";
import MintButtons from "./utils/MintButtons";

const MintPassCard = ({ mintTierId, whitelistId, address }, context) => {
  const dispatch = useDispatch();
  const { account, provider } = useWeb3React();

  const [mintPrice, setMintPrice] = useState(null);
  const [mintEnabled, setMintEnabled] = useState(false);
  const [availableAmount, setAvailableAmount] = useState(0);
  const [alreadyMintedAmount, setAlreadyMintedAmount] = useState(0);

  const mintTier = useSelector((state) =>
    getMintTierAttributes(state, mintTierId)
  );

  const getMintEnabled = async () => {
    if (!mintTier?.enabled_abi?.name) return true;
    dispatch(
      genericAbiRead({
        functionAbi: mintTier?.enabled_abi,
        contractAddress: mintTier?.contract_address,
        account: account,
        provider: provider,
        successCallbackFnc: (data) => {
          setMintEnabled(data);
          // dispatch(mintingInProgressSuccess());
        },
        errorCallbackFnc: (data) => {
          // alert("Error on Mint Price");
          // alert(data);
          // dispatch(mintError(data));
          console.log("mint enabled error", data);
        },
      })
    );
  };

  const getAvailableAmount = async () => {
    if (!mintTier?.available_amount_abi?.name) return true;
    dispatch(
      genericAbiRead({
        functionAbi: mintTier?.available_amount_abi,
        contractAddress: mintTier?.contract_address,
        account: account,
        provider: provider,
        genericInputs: {
          key: account,
        },
        successCallbackFnc: (data) => {
          console.log("available amount", data);
          setAvailableAmount(
            parseInt(data?._isBigNumber ? data?.toString() : data)
          );
          // dispatch(mintingInProgressSuccess());
        },
        errorCallbackFnc: (data) => {
          // alert("Error on Available Mint Amount");
          // alert(data);
          console.log("available amount", data);
          // dispatch(mintError(data));
        },
      })
    );
  };

  const getAlreadyMintedAmount = async () => {
    if (!mintTier?.already_minted_amount_abi?.name) return true;
    dispatch(
      genericAbiRead({
        functionAbi: mintTier?.already_minted_amount_abi,
        contractAddress: mintTier?.contract_address,
        account: account,
        provider: provider,
        genericInputs: {
          key: account,
        },
        successCallbackFnc: (data) => {
          setAlreadyMintedAmount(
            parseInt(data?._isBigNumber ? data.toString() : data)
          );
          // dispatch(mintingInProgressSuccess());
        },
        errorCallbackFnc: (data) => {
          // alert("Error on Already Minted Amount");
          // alert(data);
          // dispatch(mintError(data));
          console.log("already minted", data);
        },
      })
    );
  };

  const getMintPrice = async () => {
    if (!mintTier?.price_abi?.name) return 0;
    dispatch(
      genericAbiRead({
        functionAbi: mintTier?.price_abi,
        contractAddress: mintTier?.contract_address,
        account: account,
        provider: provider,
        successCallbackFnc: (data) => {
          setMintPrice(data?._isBigNumber ? data.toString() : data);
          // dispatch(mintingInProgressSuccess());
        },
        errorCallbackFnc: (data) => {
          // alert("Error");
          // alert(data);
          // dispatch(mintError(data));
          console.log("mintPrice error", data);
        },
      })
    );
  };

  useEffect(() => {
    getMintPrice();
    getAvailableAmount();
  }, []);

  useEffect(() => {
    getMintEnabled();
    getAlreadyMintedAmount();
    const interval = setInterval(() => {
      getMintEnabled();
      getAlreadyMintedAmount();
    }, 5000); //refresh every 5 seconds enabled and available amount
    return () => clearInterval(interval);
  }, []);

  return (
    <Card
      className="shadow mb-3 border-primary bg-transparent"
      style={{ minHeight: "45vh" }}
    >
      <CardBody className="p-5">
        <div className="text-center">
          {/* <Badge color="secondary" className="w-50 mb-4">
            {mintTier?.title}
          </Badge> */}
          <span className="text-white text-uppercase h3">
            {mintTier?.title}
          </span>
          <div className="my-4 px-3">
            {!!availableAmount && availableAmount > 0 && (
              <>
                <p>
                  You are eligible to mint {availableAmount}{" "}
                  {availableAmount === 1 ? "Nft" : "Nft's"}
                </p>
                <p className="font-weight-bold h2">
                  {alreadyMintedAmount}/{availableAmount}
                </p>
                <small>{context.t("Already minted / wallet")}</small>
              </>
            )}
          </div>
          {mintPrice > 0 ? (
            <p className="p font-weight-bold">
              {context.t("Mint for {mintPrice} ETH / each", {
                mintPrice: mintPrice
                  ? ethers.utils.formatEther(mintPrice)
                  : "?",
              })}
            </p>
          ) : (
            <p className="p font-weight-bold">{context.t("Mint for free")}</p>
          )}
          <MintButtons
            whitelistId={whitelistId}
            mintTierId={mintTierId}
            mintPrice={mintPrice}
            availableAmount={availableAmount}
            alreadyMintedAmount={alreadyMintedAmount}
            disabled={
              !mintEnabled ||
              availableAmount <= 0 ||
              availableAmount - alreadyMintedAmount <= 0
            }
            address={address}
          />
        </div>
      </CardBody>
    </Card>
  );
};

/**  define proptype for the 'translation' function  */
MintPassCard.contextTypes = {
  t: PropTypes.func,
};

MintPassCard.propTypes = {
  whitelistId: PropTypes.number,
  mintTierId: PropTypes.string,
  address: PropTypes.string,
};

MintPassCard.defaultProps = {};

export default MintPassCard;

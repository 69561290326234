import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import PropTypes from "prop-types";
// import WertModule from "@wert-io/module-react-component";
import WertWidget from "@wert-io/widget-initializer";
import { v4 as uuidv4 } from "uuid";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import {
  mintingInProgressSuccess,
  genericAbiWrite,
  genericAbiRead,
  mintError,
  transactionSuccess,
} from "../../../../redux/contracts";
import {
  getMintTierAttributes,
  generateWertSignature,
} from "../../../../redux/mintTiers";
import { addToDictionary } from "../../../../redux/transactionRequests";
import { isServer } from "../../../../redux/store";
import checkCurrency from "../../../../utils/checkForCurrency";
import {
  REACT_APP_WERT_PARTNER_ID,
  REACT_APP_WERT_ORIGIN,
  REACT_APP_PROJECT_ID,
} from "../../../../utils/env";

const MintButtons = (
  { mintTierId, mintPrice, disabled, availableAmount, alreadyMintedAmount },
  context
) => {
  const dispatch = useDispatch();
  const { account, provider } = useWeb3React();
  const formattedMintPrice = mintPrice
    ? ethers.utils.formatEther(mintPrice)
    : mintPrice;
  const [balance, setBalance] = useState(null);
  useEffect(() => {
    if (!!account && !!provider) {
      let stale = false;

      provider
        .getBalance(account)
        .then((balance) => {
          if (!stale) {
            setBalance(balance.toString());
          }
        })
        .catch(() => {
          if (!stale) {
            setBalance(null);
          }
        });

      return () => {
        stale = true;
        setBalance(undefined);
      };
    }
  }, [account, provider]);

  const hasEnoughBalance = mintPrice
    ? account &&
      balance &&
      ethers.utils.formatEther(balance) > ethers.utils.formatEther(mintPrice)
    : true;
  const maxAmount = availableAmount - alreadyMintedAmount;
  console.log("mintPrice", mintPrice);

  const mintTier = useSelector((state) =>
    getMintTierAttributes(state, mintTierId)
  );

  const [tokenCounter, setTokenCounter] = useState(1);
  const decrement = () => {
    if (tokenCounter > 1) {
      setTokenCounter(tokenCounter - 1);
    }
  };
  const increment = () => {
    if (tokenCounter < maxAmount) {
      setTokenCounter(tokenCounter + 1);
    }
  };
  const [showBuyModalButtons, setShowBuyModalButtons] = useState(true);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showInitialLoading, setShowInitialLoading] = useState(false);
  const [wertPaymentStatus, setWertPaymentStatus] = useState(null);
  const [wertError, setWertError] = useState(null);

  const [preventCloseAlert, setPreventCloseAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);

  const handleMint = async () => {
    setShowBuyModal(false);
    setShowBuyModalButtons(true);
    console.log("mint price", (tokenCounter * mintPrice).toString());
    dispatch(
      genericAbiWrite({
        functionAbi: mintTier?.mint_abi,
        contractAddress: mintTier?.contract_address,
        account: account,
        payableValue: (tokenCounter * mintPrice).toString(),
        provider: provider,
        genericInputs: {
          quantity: tokenCounter,
          tokenReceiver: account,
          minter: account,
          mintpass: {
            // TODO  mintpass object should be build with signature_type
            wallet: account,
            tier: mintTier?.internal_tier_id,
          },
          mintpassSignature: "0x" + mintTier?.mint_pass_signature,
        },
        successCallbackFnc: (data) => {
          console.log("success Data", data);
          dispatch(mintingInProgressSuccess(data));
        },
        errorCallbackFnc: (data) => {
          console.log("error Data", data);
          dispatch(mintError(data));
        },
        transactionCallbackFnc: (data) => {
          console.log("transfer data", data);
          dispatch(transactionSuccess(data));
        },
      })
    );
  };

  const wertOptions = {
    partner_id: REACT_APP_WERT_PARTNER_ID,
    origin: REACT_APP_WERT_ORIGIN,
    container_id: "wert",
    click_id: uuidv4(),
    width: 400,
    height: 600,
    listeners: {
      loaded: () => setShowInitialLoading(false),
      close: () => console.log("WERT close"),
      position: (data) => console.log("WERT step:", data.step),
      error: (data) => setWertError(data),
      "payment-status": (data) => {
        setWertPaymentStatus(data);
        if (data?.status === "success") {
          dispatch(
            addToDictionary({
              id: data.tx_id,
              transactionPreview: context.t("Paid Transaction"),
              transactionRequestStatus: "success",
            })
          );
          setSuccessAlert(false);
          //"TODO: store transaction in bowline wallet"
        } else if (data?.status === "pending") {
          setSuccessAlert(true);
          //"TODO: store transaction in bowline wallet"
          dispatch(
            addToDictionary({
              id: data.tx_id,
              transactionPreview: context.t("Paid Transaction"),
              transactionRequestStatus: "pending",
            })
          );
        }
      },
    },
  };
  console.log(wertPaymentStatus, wertError);
  const preventCloseModal =
    wertPaymentStatus &&
    (wertPaymentStatus.status === "progress" ||
      typeof wertPaymentStatus.status === "undefined");

  const closeBuyModal = () => {
    if (!preventCloseModal) {
      setShowBuyModal(false);
      setShowBuyModalButtons(true);
      setWertPaymentStatus(null);
      setWertError(null);
      setPreventCloseAlert(false);
      setSuccessAlert(false);
      setShowInitialLoading(false);
    } else {
      setPreventCloseAlert(true);
    }
  };
  const handleBuyMint = () => {
    setShowInitialLoading(true);
    setShowBuyModalButtons(false);

    const commodityAmountWei = (tokenCounter * mintPrice).toString();
    const commodityAmount = ethers.utils.formatEther(commodityAmountWei);

    const genericInputs = {
      quantity: tokenCounter,
      tokenReceiver: account,
      minter: account,
      mintpass: {
        // TODO  mintpass object should be build with signature_type
        wallet: account,
        tier: mintTier?.internal_tier_id,
      },
      mintpassSignature: "0x" + mintTier?.mint_pass_signature,
    };

    let genericParameters = [];
    if (mintTier?.mint_abi?.inputs.length > 0) {
      genericParameters = mintTier?.mint_abi.inputs.map((input) => {
        return genericInputs[input.name];
      });
    }

    const truncateEth = (str, maxDecimalDigits) => {
      if (str.includes(".")) {
        const parts = str.split(".");
        return parts[0] + "." + parts[1].slice(0, maxDecimalDigits);
      }
    };

    dispatch(
      generateWertSignature({
        mint_tier_id: mintTierId,
        project_id: REACT_APP_PROJECT_ID,
        commodity_amount: truncateEth(commodityAmount, 8),
        contract_address: mintTier.contract_address,
        function_params: encodeURIComponent(JSON.stringify(genericParameters)),
        callbackFnc: (signedData) => {
          const wertWidget = new WertWidget({
            ...signedData,
            ...wertOptions,
          });
          wertWidget.mount();
        },
      })
    );
  };

  const handleFreeMint = () => {
    setShowInitialLoading(true);
    setShowBuyModalButtons(false);
    // HARDCODED
    dispatch(
      genericAbiRead({
        functionAbi: {
          inputs: [],
          name: "mintPrice",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        contractAddress: mintTier?.contract_address,
        account: account,
        provider: provider,
        successCallbackFnc: (data) => {
          const tmpMintPrice = data?._isBigNumber ? data.toString() : data;
          // dispatch(mintingInProgressSuccess());
          const commodityAmountWei = (
            (tokenCounter * tmpMintPrice) /
            10
          ).toString();
          const commodityAmount = ethers.utils.formatEther(commodityAmountWei);

          const genericInputs = {
            quantity: tokenCounter,
            tokenReceiver: account,
            minter: account,
            mintpass: {
              // TODO  mintpass object should be build with signature_type
              wallet: account,
              tier: mintTier?.internal_tier_id,
            },
            mintpassSignature: "0x" + mintTier?.mint_pass_signature,
          };

          let genericParameters = [];
          if (mintTier?.mint_abi?.inputs.length > 0) {
            genericParameters = mintTier?.mint_abi.inputs.map((input) => {
              return genericInputs[input.name];
            });
          }

          const truncateEth = (str, maxDecimalDigits) => {
            if (str.includes(".")) {
              const parts = str.split(".");
              return parts[0] + "." + parts[1].slice(0, maxDecimalDigits);
            }
          };

          dispatch(
            generateWertSignature({
              mint_tier_id: mintTierId,
              project_id: REACT_APP_PROJECT_ID,
              commodity_amount: truncateEth(commodityAmount, 8),
              contract_address: mintTier.contract_address,
              function_params: encodeURIComponent(
                JSON.stringify(genericParameters)
              ),
              callbackFnc: (signedData) => {
                const wertWidget = new WertWidget({
                  ...signedData,
                  ...wertOptions,
                });
                wertWidget.mount();
              },
            })
          );
        },
        errorCallbackFnc: (data) => {
          alert("Something went wrong, please refresh and try again");
          console.log("Mint Error", data);
        },
      })
    );
  };
  const checkStatus =
    !isServer &&
    window.ethereum &&
    window.ethereum.networkVersion &&
    typeof window.ethereum.networkVersion === "string";

  console.log("avilable amount", availableAmount - alreadyMintedAmount <= 0);

  return (
    <>
      <div className="d-flex justify-content-around px-5 py-3">
        <Button
          className="rounded-circle text-white border-white fw-bold"
          onClick={decrement}
          outline
          disabled={tokenCounter <= 1}
          style={{ width: "45px", height: "45px" }}
        >
          -
        </Button>
        <div className="d-flex align-items-center">
          <span className="h2">{tokenCounter}</span>
        </div>
        <Button
          className="rounded-circle text-white border-white fw-bold"
          onClick={increment}
          outline
          disabled={tokenCounter >= maxAmount}
          style={{ width: "45px", height: "45px" }}
        >
          +
        </Button>
      </div>
      <div className="my-3 d-grid gap-2">
        <Button
          className="btn bg-gradient border-0 text-white fw-bold"
          // outline
          disabled={!!availableAmount && disabled}
          color="info"
          onClick={() => {
            setShowBuyModal(true);
            setShowBuyModalButtons(true);
          }}
        >
          {context.t("Mint")}
        </Button>
        <Modal
          isOpen={showBuyModal}
          toggle={closeBuyModal}
          scrollable
          className="text-light bg-dark"
        >
          <ModalHeader toggle={closeBuyModal} className="text-light bg-dark">
            {context.t("Mint your NFT")}
          </ModalHeader>
          <ModalBody className="text-light bg-dark">
            {/* div with centered content */}
            <div className="d-flex justify-content-center">
              {showInitialLoading && <Spinner />}
            </div>
            {showBuyModalButtons && (
              <div>
                <p>
                  {context.t("Pelase select an option to pay for this mint")}
                </p>
                {!mintPrice && (
                  <strong>
                    {context.t(
                      "This is a free mint, you just have to pay gas fees!"
                    )}
                  </strong>
                )}
                <Button
                  className="btn mt-2"
                  block
                  disabled={!!availableAmount && disabled}
                  color="primary"
                  onClick={handleMint}
                >
                  {context.t("Mint with Crypto")}
                </Button>

                {!hasEnoughBalance && (
                  <span className="form-text">
                    {context.t(
                      "We realized that your balance is too low. You need to have at least "
                    )}
                    {formattedMintPrice}
                    {checkStatus && checkCurrency()}
                  </span>
                )}
                <Button
                  className="btn mt-4"
                  outline
                  block
                  disabled={!!availableAmount && disabled}
                  color="primary"
                  onClick={!mintPrice ? handleFreeMint : handleBuyMint}
                >
                  {context.t("Pay with Credit Card")}
                </Button>
                {!mintPrice && (
                  <p className="small mt-3">
                    {context.t(
                      "Although this is a Free Mint, when minting with Credit Card we'll charge additionial (approx.) 5 USD that are transfered to your wallet as crypto. This way you can pay further transactions with your crypto balance."
                    )}
                  </p>
                )}
              </div>
            )}
            {preventCloseModal && preventCloseAlert && (
              <Alert color="danger">
                {context.t("Please wait for the payment to be completed")}
              </Alert>
            )}
            {successAlert && (
              <Alert color="success">
                {context.t(
                  "The transaction has been sent and you will receive a notification in your bowline wallet"
                )}
              </Alert>
            )}
            <div className="d-flex justify-content-center" id="wert">
              {/* <WertModule options={wertOptions} /> */}
            </div>
          </ModalBody>
        </Modal>
        {!!availableAmount && disabled ? (
          <>
            {availableAmount <= 0 ? (
              <p>
                {context.t("Sorry, this mint tier is not available anymore.")}
              </p>
            ) : (
              <>
                {availableAmount - alreadyMintedAmount <= 0 ? (
                  <p>{context.t("Max tokens minted")}</p>
                ) : (
                  <p>{context.t("Mint Tier not enabled.")}</p>
                )}
              </>
            )}
          </>
        ) : (
          <p>{context.t("Mint now!")}</p>
        )}
      </div>
    </>
  );
};

/**  define proptype for the 'translation' function  */
MintButtons.contextTypes = {
  t: PropTypes.func,
};

MintButtons.propTypes = {
  // disabled: PropTypes.boolean,
  mintTierId: PropTypes.string,
  availableAmount: PropTypes.number,
  alreadyMintedAmount: PropTypes.number,
  mintPrice: PropTypes.string,
  disabled: PropTypes.bool,
};

MintButtons.defaultProps = {};

export default MintButtons;

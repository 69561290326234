/**
 * The Events container
 */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Page from "../../components/Page";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { useWeb3React } from "@web3-react/core";
import {
  listTiersForProject,
  getAvailableMintTierContracts,
} from "../../redux/mintTiers";
import { mintErrorReset } from "../../redux/contracts";
// import PageHead from "../../components/PageHead";
import ScrollToTop from "../../components/Utils/Scroll/ScrollToTop";
import ContractMints from "../../components/Minting/ContractMints";
import TransactionSuccessModal from "../../components/Minting/TransactionSuccessModal";
import ErrorModal from "../../components/ErrorModal";
import MintProgressModal from "../../components/Minting/MintProgressModal/MintProgressModal-component";
import { REACT_APP_PROJECT_ID } from "../../utils/env";
import ConnectWallet from "../../components/ConnectWallet";
import header from "../../assets/images/NFGbanner.png";
import Body_NFG from "../../assets/images/Body_NFG.png";
import ReactPlayer from "react-player";

import draw1 from "../../assets/images/draw-1.jpg";
import draw2 from "../../assets/images/draw-2.jpg";
import draw3 from "../../assets/images/draw-3.jpg";
import draw4 from "../../assets/images/draw-4.jpg";
import draw5 from "../../assets/images/draw-5.jpg";
import draw6 from "../../assets/images/draw-6.jpg";
import draw7 from "../../assets/images/draw-7.jpg";

/**
 * MintingSection container used as app default landing page
 */
const MintingSection = (props, context) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();

  const mintSuccess = useSelector((state) => state?.contracts?.mintSuccess);

  const mintError = useSelector((state) => state?.contracts?.mintError);

  const mintErrorMessage = useSelector(
    (state) => state?.contracts?.mintErrorMessage
  );

  useEffect(() => {
    account &&
      dispatch(
        listTiersForProject({
          projectId: REACT_APP_PROJECT_ID,
          address: account,
        })
      );
    window.scrollTo(0, 0);
  }, [dispatch, REACT_APP_PROJECT_ID, account]);

  const mintTierIds = useSelector((state) => state?.mintTiers?.idsList);

  const contracts = useSelector((state) =>
    getAvailableMintTierContracts(state, mintTierIds)
  );

  return (
    <Page id="MintingSection" title={context.t("Minting Section")}>
      <ScrollToTop />
      <div className="bg-dark">
        <a href="https://www.nft.london/" target="_blank" rel="noreferrer">
          <img src={header} alt="nfg-logo" className="w-100" />
        </a>
      </div>
      <Container>
        {/* <PageHead title={context.t("Minting Section")} backTarget={"/"} /> */}
        <Row
          className="justify-content-between pb-md-5 mb-md-5 gx-5"
          style={{ marginTop: "7vh" }}
        >
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column justify-content-center"
          >
            <h1 className="display-5 fw-bold text-uppercase me-5">
              The Entelechy NFG LDN Piece Limited 250
            </h1>
            <div className="d-flex flex-column justify-content-between pe-5 mb-4">
              <p className="bigger-margin pb-5">
                Claim your own piece of web3 history right now and mint this
                exclusive and limited NFG artpiece, only available for our NFG
                holders and visitors of the NFT.LONDON. Holders of the Entelechy
                NFG can join the raffle of the rarest NFG - The Golden NFG.
                There will be only 7 unique 1 on 1 available on ethereum.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} className="mt-md-5">
            <div className="bg-dark text-center">
              <ReactPlayer
                width="100%"
                height="100%"
                controls={true}
                playing={false}
                muted={true}
                loop={true}
                url="https://meta.bowline.app/nfg/ENTELECHY.mp4"
              />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between py-md-5 my-md-5 gx-5">
          <Col xs={12} md={12}>
            <div className="ml-5 d-flex flex-column minting-wrapper">
              {account ? (
                <>
                  {contracts.map((contractAddress) => (
                    <ContractMints
                      contractAddress={contractAddress}
                      key={contractAddress}
                    />
                  ))}
                </>
              ) : (
                <>
                  {" "}
                  <p className={"mt-5"}>
                    {context.t(
                      "Please connect to your account if you would like to purchase an NFT."
                    )}{" "}
                    <br />
                    {context.t(
                      "Don't have a wallet yet? No problem, just follow our step-by-step guide which you can also find behind this button."
                    )}
                  </p>
                  <ConnectWallet
                    buttonColor="primary"
                    buttonSize="lg"
                    buttonId="nav-connect-wallet"
                    className="bg-gradient border-0 text-white"
                  />{" "}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <img src={Body_NFG} alt="nfg-logo" className="w-100" />
      </Container>
      <Container>
        <Row className="pt-5 mt-5">
          <Col xs={12} md={3} className="pb-4">
            <img src={draw1} className="w-100" />
          </Col>
          <Col xs={12} md={3} className="pb-4">
            <img src={draw2} className="w-100" />
          </Col>
          <Col xs={12} md={3} className="pb-4">
            <img src={draw3} className="w-100" />
          </Col>
          <Col xs={12} md={3} className="pb-4">
            <img src={draw4} className="w-100" />
          </Col>
          <Col xs={12} md={3} className="pb-4">
            <img src={draw5} className="w-100" />
          </Col>
          <Col xs={12} md={3} className="pb-4">
            <img src={draw6} className="w-100" />
          </Col>
          <Col xs={12} md={3} className="pb-4">
            <img src={draw7} className="w-100" />
          </Col>
        </Row>
      </Container>
      {mintSuccess && (
        <TransactionSuccessModal
          success={mintSuccess}
          redirectUrl={`/minting`}
          resourceType="token"
          action="minted"
        />
      )}
      <MintProgressModal />
      {mintError &&
        mintErrorMessage?.errorMessage !== "transaction is not defined" && (
          <ErrorModal
            error={mintError}
            errorReset={mintErrorReset}
            customErrorBody={mintErrorMessage?.errorMessage}
          />
        )}
    </Page>
  );
};

/**  define proptype for the 'translation' function  */
MintingSection.contextTypes = {
  t: PropTypes.func,
};

MintingSection.propTypes = {
  skipLazyLoading: PropTypes.bool,
};

/** export the component as redux connected component */
export default withRouter(MintingSection);

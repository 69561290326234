import React from "react";
import PropTypes from "prop-types";

const MintSoldoutScreen = ({ contractAddress }, context) => {
  return (
    <div className="my-5 py-5">
      <div className="mb-4">
        <p className="h2 pl-0 font-weight-bold">{context.t("Sold out!")}</p>
        <p className="h2 pl-0 font-weight-bold">
          {context.t("Please visit the")}
          <br />
          {context.t("secondary market.")}
        </p>
      </div>
      <div className="d-flex text-left">
        <a
          className="btn-primary rounded-pill mr-3 p-2 text-center"
          style={{ width: "120px" }}
          href={`https://opensea.io/collection/${contractAddress}`}
          target="_blank"
          rel="noreferrer"
        >
          {context.t("OpenSea")}
        </a>
        <a
          className="btn-secondary rounded-pill ml-3 p-2 text-center"
          style={{ width: "120px" }}
          href={`https://looksrare.org/collections/${contractAddress}`}
          target="_blank"
          rel="noreferrer"
        >
          {context.t("LooksRare")}
        </a>
      </div>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
MintSoldoutScreen.contextTypes = {
  t: PropTypes.func,
};

MintSoldoutScreen.propTypes = {
  contractAddress: PropTypes.string,
};

MintSoldoutScreen.defaultProps = {};

export default MintSoldoutScreen;

import React from "react";
import PropTypes from "prop-types";
import { isServer } from "../../redux/store";
import { useSelector } from "react-redux";
import { Alert, Button } from "reactstrap";
import { getContractAttributes } from "../../redux/contracts";

const NetworkBar = ({ contractByAddress }, context) => {
  let compResponse = null;

  const contractData = useSelector((state) =>
    getContractAttributes(state, contractByAddress)
  );

  const handleNetworkChangePolygon = () => {
    window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x89",
          rpcUrls: ["https://polygon-rpc.com/"],
          chainName: "Polygon Mainnet",
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
          },
          blockExplorerUrls: ["https://polygonscan.com/"],
        },
      ],
    });
  };
  const handleNetworkChangeMumbai = () => {
    window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x13881",
          rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
          chainName: "Mumbai Testnet",
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
          },
          blockExplorerUrls: ["https://polygonscan.com/"],
        },
      ],
    });
  };

  if (
    !isServer &&
    !contractData?.network &&
    window.ethereum &&
    window.ethereum.networkVersion &&
    typeof window.ethereum.networkVersion === "string" &&
    window.ethereum.networkVersion !== (0x01).toString(16)
  ) {
    compResponse = (
      <Alert color="danger">
        <div>
          {context.t(
            "You are currently not connected to the Ethereum Network. Please change your network in metamask."
          )}
        </div>
      </Alert>
    );
  }
  if (
    !isServer &&
    window?.ethereum?.networkVersion &&
    typeof window.ethereum.networkVersion === "string" &&
    contractData?.network === "rinkeby" &&
    window.ethereum.networkVersion !== (0x4).toString(16)
  ) {
    compResponse = (
      <Alert color="danger">
        <div>
          {context.t(
            "You are currently not connected to the Rinkeby Test-Network. Please change your network in metamask."
          )}
        </div>
      </Alert>
    );
  }
  if (
    !isServer &&
    window?.ethereum?.networkVersion &&
    typeof window.ethereum.networkVersion === "string" &&
    contractData?.network === "ethereum" &&
    window.ethereum.networkVersion !== (0x1).toString(16)
  ) {
    compResponse = (
      <Alert color="danger">
        <div>
          {context.t(
            "You are currently not connected to the Ethereum Network. Please changeyour network in metamask."
          )}
        </div>
      </Alert>
    );
  }
  if (
    !isServer &&
    window?.ethereum?.networkVersion &&
    typeof window.ethereum.networkVersion === "string" &&
    contractData?.network === "polygon" &&
    window.ethereum.networkVersion !== (0x137).toString(16)
  ) {
    compResponse = (
      <Alert color="danger" className="text-center">
        <div>
          {context.t(
            "You are currently not connected to the Polygon Network. Please change your network in MetaMask."
          )}
        </div>
        <Button
          className="btn-outline-primary bg-transparent border-2 mt-3"
          onClick={handleNetworkChangePolygon}
        >
          {context.t("Add Polygon network")}
        </Button>
      </Alert>
    );
  }

  if (
    !isServer &&
    window?.ethereum?.networkVersion &&
    typeof window.ethereum.networkVersion === "string" &&
    contractData?.network === "mumbai" &&
    window.ethereum.networkVersion !== (0x13881).toString(16)
  ) {
    compResponse = (
      <Alert color="danger" className="text-center">
        <div>
          {context.t(
            "You are currently not connected to the Mumbai Network. Please change your network in MetaMask."
          )}
        </div>
        <Button
          className="btn-outline-primary bg-transparent border-2 mt-3"
          onClick={handleNetworkChangeMumbai}
        >
          {context.t("Add Mumbai network")}
        </Button>
      </Alert>
    );
  }
  return compResponse;
};

/**  define proptype for the 'translation' function  */
NetworkBar.contextTypes = {
  t: PropTypes.func,
};

NetworkBar.propTypes = {
  contractByAddress: PropTypes.string,
};

export default NetworkBar;

import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch } from "react-redux";

const ErrorModal = (props) => {
  const dispatch = useDispatch();
  const { error, errorReset, customErrorBody } = props;

  return (
    <Modal isOpen={error ? true : false} toggle={() => dispatch(errorReset())}>
      <ModalHeader toggle={() => dispatch(errorReset())}>Error</ModalHeader>
      <ModalBody>
        <p className="text-danger font-weight-bold">
          <span role="img" aria-label="sheep">
            ⚠️
          </span>{" "}
          {error}
        </p>
        <p className="text-info">{customErrorBody}</p>
      </ModalBody>
    </Modal>
  );
};

ErrorModal.propTypes = {
  error: PropTypes.string,
  errorReset: PropTypes.func,
  customErrorBody: PropTypes.string,
};

ErrorModal.defaultProps = {};

export default ErrorModal;
